import React, { useEffect, useMemo, useRef, useState } from 'react';
import { graphql } from "gatsby"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import HackerText from '../components/hackerText';
import { Helmet } from "react-helmet"
import Winnerss3 from "../components/winnerss3";
import PartnerIconsShort from "../components/partners-icons-short";
import thbTronLogo from '../images/hackatrons5/header-thb-tron-logo.png';
import thbHTXLogo from '../images/hackatrons5/header-thb-htx-logo.png';
import thbBTTCLogo from '../images/hackatrons5/header-thb-bttc-logo.png';
import thbLogo from '../images/hackatrons6/tron-htx-bttc-jd.png';
import CountdownTimerCommon from "../components/countdownTimerCommon";
import { Benefit } from "../components/hackatron/benefit";
import { BroughtToYouBy } from "../components/hackatron/broughtToYouBy";
import { Trackss5 } from '../components/hackatron/trackss5';
import { HackaTronVideo } from '../components/hackatron/hackaTronVideo';
import { Highlights } from '../components/hackatron/highlights';
import { Winners } from '../components/hackatron/winners';
import { HackatronButton } from '../components/hackatron/button';
import { Developers } from '../components/hackatron/developer';

const firstDateCount = new Date("July 25 2024 09:00:00 GMT-0800").getTime();
const secondDateCount = new Date("October 8 2024 00:00:00 GMT-0800").getTime();

const Hackathon = ({ pageContext: { t }, data: { allWpPartner, allGrandPrize, allWinners } }) => {
  const ref = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  const [timeSecond, setTimeSecond] = useState(firstDateCount);
  const [countEnd,setCountEnd] = useState(false);
  const [countConfig,setCountConfig] = useState({
    url:'https://hackatron7.devpost.com/',
    title:'Qualifiers Announcement',
    btnText:'More info'
  })
  useEffect(() => {
    let timer = null;
    timer = setInterval(() => {
      let now = new Date().getTime();
      if (now > timeSecond) {
        setTimeSecond(secondDateCount);
      }
      if(now>secondDateCount&&!countEnd){
        setCountEnd(true)
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [countEnd]);
  useEffect(() => {
    window.location.replace('/')
    let observer = new IntersectionObserver(entries => {
      entries.forEach((item) => {
        if (item.isIntersecting||item.boundingClientRect.top<0) {
          item.target.classList.add(["fade-in"]);
        }
      })
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    })
    const eles = document.querySelectorAll('.first-load');
    eles.forEach(ele => {
      observer.observe(ele);
    })
  }, []);
  useEffect(() => {
    if (secondDateCount === timeSecond) {
      setCountConfig({
        url:'https://hackatron7.devpost.com/',
        title:'Qualifiers Announcement',
        btnText:'More Info'
      })
    }
  }, [timeSecond])
  return <></>
  return (
    <>
      <Helmet>
        <title>HackaTRON | TRONDAO</title>
        <meta name="description"
          content="TRON Grand Hackathon where blockchain developers get to compete to build decentralized apps. See past winners and compete in the next season here." />
        <meta property="og:description"
          content="TRON Grand Hackathon where blockchain developers get to compete to build decentralized apps. See past winners and compete in the next season here." />
        <meta property="og:title" content="Hackatron | TRONDAO" />
      </Helmet>
      <div className="index hackatrons5" ref={ref}>
        <div className="hackatrons5-header">
          <div className="hackatrons5-header-wrapper">
            <div className="col-12 text-left pl-0 pr-0">
              <h2 className="hackatrons5-header-title hackatrons5-header-title-sm">TRON Hackathon Season 7</h2>
              <div className='hackatrons5-header-logo' />
              <div className='hackatrons5-header-title hackatrons5-header-title-sm'>July 25- October 8</div>
              <div className='hackatrons5-border' />
              <h2 className="hackatrons5-header-title">Up to <span className="text-danger">$650,000</span> in prizes</h2>
              <div className='hackatrons5-border' />
              {!countEnd&&
              <>
                {/* <p className="hackatrons5-header-title-last">{countConfig.title}</p> */}
                <CountdownTimerCommon countDownDate={timeSecond} />
                {/* <HackatronButton cls={"register"} btnText={countConfig.btnText} showArrow={false} handleClick={() => {
                  window.open(countConfig.url, "__blank");
                }} /> */}
              </>}
              <HackatronButton cls={"register"} btnText={countConfig.btnText} showArrow={false} handleClick={() => {
                  window.open(countConfig.url, "__blank");
                }} />
            </div>
          </div>
          <div className='hackatrons5-header-thb'>
              <img className='hackatrons5-header-thb-logo' src={thbLogo}/>
          </div>
        </div>
        <div className="first-load bg-lines">
          <Benefit />
        </div>
        <div className="first-load linear linear">
          <HackaTronVideo btnShow={true} btnConfig={countConfig} videoCover="https://i.ytimg.com/vi/TxigXGgdJOc/hqdefault.jpg" videoUrl="https://www.youtube.com/embed/TxigXGgdJOc?si=bERNAFkvIDZQscUM" />
        </div>
        <div>
          <BroughtToYouBy data={allWpPartner.edges}/>
        </div>
        <div className="first-load tracks linear-left">
          <Trackss5 />
        </div>
        <div className='first-load'>
          <Developers />
        </div>
        <div className='first-load'>
          <Highlights />
        </div>
        {/* <div className="first-load mx-auto" style={{ maxWidth: '1920px' }}>
          <div
            className="pb-md-5 d-flex flex-column align-items-center justify-content-center text-decoration-none text-white">
            <h2 id="partners" class="w-100 text-center section-header text-white">Partners</h2>
            <div className="section-description mx-auto text-white">Crypto’s most prestigious institutions come
              together to offer their expertise as they judge
              all around blockchain inventions and accelerate the growth of the TRON DAO ecosystem.
            </div>
          </div>
          <PartnerIconsShort data={allWpPartner} />
        </div>
        <div className="first-load winners linear">
          <Winners data={allGrandPrize} />
        </div>
        <div className='first-load linear linear-left'>
          <Winnerss3 data={allWinners} />
        </div> */}
      </div>
    </>
  )

}

export default Hackathon

export const query = graphql`
query($in: [String] = ["PrensentedBy","partner","dataPartner","sponsor","securityPartner"]) {  
    allGrandPrize: allWpProject(
    filter: {hackathonProjectInfo: {winnersPrize: {eq: "1st Place"}, projectAWinner: {eq: "Yes"}}}
  ) {
    nodes {
      title
      hackathonSeasonsAndTracks {
        hackathonSeason
        hackathonTrack
      }
      hackathonProjectInfo {
        projectAWinner
        projectDescription
        winnersPrize
        hackathonTeamPic {
          sourceUrl
        }
      }
      slug
    }
  }
    allWinners: allWpProject (sort: {fields: hackathonProjectInfo___winnersPrize} 
  ) {
    nodes {
      title
      hackathonSeasonsAndTracks {
        hackathonSeason
        hackathonTrack
      }
      hackathonProjectInfo {
        projectAWinner
        projectDescription
        winnersPrize
        hackathonTeamPic {
          sourceUrl
        }
      }
      slug
    }
  }
  allWpArticle {
    nodes {
      title
      hackathonSeasonsAndTracks {
        hackathonSeason
        hackathonTrack
      }
      articleInfo {
        articlePic {
          sourceUrl
        }
        articleUrl
      }
    }
  }
  allWpPartner(
    sort: {order: ASC, fields: menuOrder}
    filter: {partnerInfo: {partnerType: {in: $in}}}
  ) {
  edges {
    node {
      id
      uri
      title
      hackathonSeasonsAndTracks {
        hackathonSeason
        hackathonTrack
      }
      partnerInfo {
        partnerUrl
        partnerBio
        partnerLogo {
          sourceUrl
        }
        partnerType
      }
    }
  }
}
}

`
